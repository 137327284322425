<template>
    <div class="plan-info-card" >
        <div class="plan-info p-4"  v-bind="$attrs">
           <slot>
           </slot>
        </div>
       
    </div>
</template>
<script>
export default ({
   data(){
    return{

    }
   }
})
</script>
<style scoped>
.plan-info-card{
 background: #25242e;
border-radius: 12px;

}
.plan-info{
    background-image: url('./assets/wave.svg');
background-repeat: no-repeat;
min-height: 172px;
border-radius: 12px;
color:#ffff;
background-size: cover;
}
svg{
    transform: scaleX(-1);
    
}
</style>
